<template>
    <!-- Page: pages/calendar-bulma -->
    <div class="container">
        <alert/>
    </div>

    <br/>

    <div class="container mobile-contain" style="display: flow-root;">
        <h1 v-if="user && user.first_name">Welcome, {{ user.first_name }}</h1>
<!--        <userTools/>-->
    </div>

    <br/>

    <div class="container">
        <bulma-calendar></bulma-calendar>
    </div>
    <!-- End Page: pages/calendar-bulma  -->
</template>

<script>
    import Alert from "@/alivue/components/alert";
    import UserTools from "@/alivue/components/user-tools";
    import SinceYouLastBlock from '@/alivue/components/since-you-last-block.vue';
    import InterestedInBlock from '@/alivue/components/interested-in-block.vue';
    import SavedContactBlock from '@/alivue/components/saved-contact-block.vue';
    import SearchBlock from "./search-block";
    import BulmaCalendar from "./bulma-calendar";
    import { computed, onMounted } from "vue";
    import { useStore } from "vuex";
    import { ACTION_TYPES } from "@/constants";
    import useUser from "@/helpers/useUser";

    export default {
        components: {
            Alert,
            UserTools,
            SinceYouLastBlock,
            InterestedInBlock,
            SavedContactBlock,
            SearchBlock,
            BulmaCalendar
        },
        setup() {
            const store = useStore();
            const bookmarkedIdsLoaded = computed(() => store.getters.isBookmarkedIdsLoaded);
            const hasBookmarks = computed(
                () => store.getters.isBookmarkedIdsLoaded && store.getters.bookmarkedIds.length > 0
            );
            const {user} = useUser();
            onMounted(() => {
                if (!bookmarkedIdsLoaded.value) {
                    store.dispatch(ACTION_TYPES.loadBookmarkIds);
                }
            });

            return {hasBookmarks, user}
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/_variables.scss";
    @import "../../assets/scss/mixins/_bemify.scss";
    .filter-wrapper {
        max-width: 50%;
        margin: auto;
        margin-top: 20px;
        @media screen and (max-width: 768px) {
            width: 90%;
            max-width: 90%;
        }
        .search-block {
            margin-bottom: 30px;
        }
    }
    .mobile-contain {
        h1 {
            float: left;
        }
        @media screen and (max-width: 1021px) {
            margin-left: 15px;
            margin-right: 15px;
            h1 {
                text-align: center;
                float: none;
            }
        }
    }
</style>
