<template>
    <!-- Component: organisms/since-you-last-block -->
    <general-container :customClass="customClass" :isLocked="false">

        <template
            v-if="updates && updates.length"
            v-for="item in updates"
            :key="item.contentId"
        >
            <component v-bind="item" v-bind:is="getComponent(item)" v-if="item.class != 'TweetRef'"/>
        </template>

        <preloader v-if="loading"></preloader>
        <p v-if="!loading && updates.length == 0">Please bookmark more Contacts, Positions, Agencies, etc. to see recent updates.</p>

        <read-more-link :contents="readMore" :url="'/recent-updates'"></read-more-link>

    </general-container>
    <!-- End Component: organisms/since-you-last-block -->
</template>

<script>
    import GeneralContainer from "./general-container"
    import InfoBlockLeadership from "./info-block--leadership"
    import InfoBlockBudget from "./info-block--budget"
    import InfoBlockTwitter from "./info-block--twitter"
    import InfoBlockEmployment from "./info-block--employment"
    import InfoBlockEmpty from "./info-block--empty"
    import ReadMoreLink from "./read-more-link"
    import RequestHandler from "@/handler/RequestHandler"
    import Preloader from "./preloader";

    export default {
        name: 'RecentNewsBlock',
        props: [
            'mobile'
        ],
        components: {
            GeneralContainer,
            InfoBlockLeadership,
            InfoBlockBudget,
            InfoBlockTwitter,
            InfoBlockEmployment,
            InfoBlockEmpty,
            ReadMoreLink,
            Preloader
        },
        mounted() {
            RequestHandler.loadRecentUpdates(3, false).then(response => {
                this.updates = response.ContentList.list;
                this.loading = false;
            }).catch((error) => {
                console.error(error);
            });
        },
        methods: {
            getComponent(item) {
                if (item.class === 'EmploymentUpdateRef') {
                    return 'info-block-employment';
                }
                if (item.class === 'TweetRef') {
                    return 'info-block-twitter';
                }
                if (item.class === 'BudgetUpdateRef') {
                    return 'info-block-budget';
                }
                if (item.class === 'EmptyRef') {
                    return 'info-block-empty';
                }
                // should never get here
                return '';
            }
        },
        data() {
            return {
                title: "Recent Activity",
                customClass: "since-you-last-block",
                readMore: "All Recent Updates",
                loading: true,
                updates: [],
            }
        }

    }
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/_variables.scss";
    @import "../../assets/scss/mixins/_bemify.scss";

</style>

