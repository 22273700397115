<template>
    <!-- Component: organisms/saved-contact-block -->
    <general-container customClass="my-saved-contacts" title="My Saved Contacts">
        <contact-line
            v-for="contact in contacts"
            :name="getContactName(contact)"
            :image-path="contact.photo?.url"
            :url="contact.url"
            :agency="contact.position?.PositionRef.agency?.AgencyRef.name"
            :agency-url="contact.position?.PositionRef.agency?.AgencyRef.url"
            :role="contact.position?.PositionRef.name + (contact.positionRole ? ' (' + contact.positionRole + ')' : '')"
            :role-url="contact.position?.PositionRef.url"
            :leadership="contact.agencyHead"
        ></contact-line>
        <p v-if="contacts.length == 0">You do not currently have any contacts saved.</p>
        <read-more-link contents="See All Saved Contacts" url="/my-bookmarks?type=contact"></read-more-link>

    </general-container>
    <!-- End Component: organisms/saved-contact-block -->
</template>

<script>
    import GeneralContainer from "./general-container"
    import ReadMoreLink from "./read-more-link"
    import ContactLine from "./contact-line"
    import RequestHandler from "@/handler/RequestHandler";
    import { onMounted, ref } from "vue";
    import { contactFullName } from "@/helpers/objects";

    export default {
        name: 'SavedContactsBlock',
        components: {
            GeneralContainer,
            ReadMoreLink,
            ContactLine
        },
        methods: {
            getContactName: function (contact) {
                return contactFullName(contact);
            },
        },
        setup() {
            const contacts = ref([]);

            onMounted(() => {
                RequestHandler.loadBookmarkedObjects('contact', 3).then((items) => {
                    contacts.value = items.list.map(item => item.contact.ContactRef);
                });
            });

            return {contacts};
        },
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/_variables.scss";
    @import "../../assets/scss/mixins/_bemify.scss";

    .red {
        color: $red;
    }

    .green {
        color: $green--light;
    }
/*
    .media + .media {
        border-top: 1px solid $blue--med;
    }
*/
</style>

