<template>
	<!-- Component: organisms/bulma-calendar -->
    <div class="container">
		<div class="calendar is-calendar-large">
			<div class="calendar-nav">
				<div class="calendar-nav-left">
					<button class="button is-link">
						<i class="fa fa-chevron-left"></i>
					</button>
				</div>
				<div>March 2017</div>
				<div class="calendar-nav-right">
					<button class="button is-link">
						<i class="fa fa-chevron-right"></i>
					</button>
				</div>
			</div>
			<div class="calendar-container">
				<div class="calendar-header">
					<div class="calendar-date">Sun</div>
					<div class="calendar-date">Mon</div>
					<div class="calendar-date">Tue</div>
					<div class="calendar-date">Wed</div>
					<div class="calendar-date">Thu</div>
					<div class="calendar-date">Fri</div>
					<div class="calendar-date">Sat</div>
				</div>
				<div class="calendar-body">
					<div class="calendar-date disabled"><button class="date-item">26</button></div>
					<div class="calendar-date disabled">
						<button class="date-item">27</button>
						<div class="calendar-events">
						<a class="calendar-event">Default event</a>
						</div>
					</div>
					<div class="calendar-date disabled"><button class="date-item">28</button></div>
					<div class="calendar-date"><button class="date-item">1</button></div>
					<div class="calendar-date"><button class="date-item">2</button></div>
					<div class="calendar-date"><button class="date-item">3</button></div>
					<div class="calendar-date tooltip" data-tooltip="Today"><button class="date-item date-today">4</button></div>
					<div class="calendar-date"><button class="date-item" disabled="">5</button></div>
					<div class="calendar-date"><button class="date-item">6</button></div>
					<div class="calendar-date"><button class="date-item">7</button></div>
					<div class="calendar-date tooltip" data-tooltip="You have appointments">
						<button class="date-item badge">8</button>
						<div class="calendar-events">
							<a class="calendar-event is-primary">Primary event</a>
							<a class="calendar-event is-warning">Warning event</a>
							<a class="calendar-event is-danger">Danger event</a>
						</div>
					</div>
					<div class="calendar-date"><button class="date-item">9</button></div>
					<div class="calendar-date"><button class="date-item">10</button></div>
					<div class="calendar-date"><button class="date-item">11</button></div>
					<div class="calendar-date">
						<button class="date-item">12</button>
						<div class="calendar-events">
							<a class="calendar-event">Default event</a>
						</div>
					</div>
					<div class="calendar-date"><button class="date-item">13</button></div>
					<div class="calendar-date"><button class="date-item">14</button></div>
					<div class="calendar-date"><button class="date-item">15</button></div>


<!-- ORIGINAL
					<div class="calendar-date calendar-range range-start"><button class="date-item is-active">16</button></div>
					<div class="calendar-date calendar-range"><button class="date-item">17</button></div>
					<div class="calendar-date calendar-range"><button class="date-item">18</button></div>
					<div class="calendar-date calendar-range"><button class="date-item">19</button></div>
					<div class="calendar-date calendar-range range-end">
						<button class="date-item is-active">20</button>
						<div class="calendar-events">
						<a class="calendar-event is-success">Success event</a>
						</div>
					</div>
 NEW BLOCK BELOW : multi-range multi-range-start etc-->

					<div class="calendar-date calendar-range range-start multiple-range range-start-1">
						<button class="date-item is-active">16</button>
						<div class="multi-range multi-range-start multi-range-1"></div>
					</div>
					<div class="calendar-date calendar-range multiple-range">
						<button class="date-item">17</button>
						<div class="multi-range multi-range-1">Example of multi-day events</div>
					</div>
					<div class="calendar-date calendar-range multiple-range range-end-1">
						<button class="date-item">18</button>
						<div class="multi-range multi-range-end multi-range-1"></div>
					</div>
					<div class="calendar-date calendar-range"><button class="date-item">19</button></div>
					<div class="calendar-date calendar-range range-end">
						<button class="date-item is-active">20</button>
						<div class="calendar-events">
						<a class="calendar-event is-success">Success event</a>
						</div>
					</div>



					<div class="calendar-date"><button class="date-item">21</button></div>
					<div class="calendar-date"><button class="date-item">22</button></div>
					<div class="calendar-date"><button class="date-item">23</button></div>
					<div class="calendar-date"><button class="date-item">24</button></div>
					<div class="calendar-date"><button class="date-item">25</button></div>
					<div class="calendar-date"><button class="date-item">26</button></div>
					<div class="calendar-date"><button class="date-item">27</button></div>
					<div class="calendar-date"><button class="date-item">28</button></div>
					<div class="calendar-date"><button class="date-item">29</button></div>
					<div class="calendar-date"><button class="date-item">30</button></div>
					<div class="calendar-date"><button class="date-item">31</button></div>
					<div class="calendar-date disabled">
						<button class="date-item">1</button>
						<div class="calendar-events">
							<a class="calendar-event">Second default event</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<!-- End Component: organisms/bulma-calendar -->
</template>

<script>

	export default {
		name: 'BulmaCalendar',
		props: [

		],
		components: {

		},
		data() {
			return {

			}
		}
	}
</script>

<style lang="scss" scoped>
	@import "../../assets/scss/_variables.scss";
	@import "../../assets/scss/mixins/_bemify.scss";
	@import "../../assets/scss/bulma/extensions/bulma-calendar/_bulma-calendar.scss";
	@import "../../assets/scss/bulma/extensions/bulma-calendar/_custom.scss";
</style>
